import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, of, Subject, Subscription, switchMap, take } from 'rxjs';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { FilterTypes } from '../enums/filterTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { CommentEventsEmitter } from '../events/comments.events';
import { ObjectEventEmitters } from '../events/object.events';
import { IApiService } from '../interfaces/iHttpService';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { RAGBreakdown } from '../models/rag/ragBreakdown';
import { RAGStatus } from '../models/rag/ragStatus';
import { FilterUtilities } from '../utilities/filter.utilities';
import { HeaderFiltersService } from './header-filters.service';
import { FixedHeaderFiltersService } from './fixed-header-filters.service';
import { SortingService } from './sorting.service';
import { ListFilteringService } from './utilities/list-filtering.service';
import { ListOperationsService } from './utilities/list-operations.service';
import { ModuleService } from './module.service';
import { LightFilterUpdateModel } from '../models/filter/LightFilterUpdateModel';
import { ObjectEventModel } from '../models/objectEventModel';
import { TrackingViews } from '../enums/tracking-view.enum';
import { TrackingService } from './tracking.service';

@Injectable()
export abstract class ListStateService<T extends ModifiableEntityViewModel> implements OnDestroy {
  private readonly bufferTime: number = 2000;
  protected readonly _items = new BehaviorSubject<T[]>([]);
  readonly items$ = this._items.asObservable();
  get items(): T[] {
    return this._items.getValue();
  }

  trackingView: TrackingViews;

  protected readonly _filteredItems = new BehaviorSubject<T[]>([]);
  readonly filteredItems$ = this._filteredItems.asObservable();
  get filteredItems(): T[] {
    return this._filteredItems.getValue();
  }

  protected readonly _searchText = new BehaviorSubject<string>('');
  readonly searchText$ = this._searchText.asObservable();
  get searchText(): string {
    return this._searchText.getValue();
  }
  set searchText(text: string) {
    this._searchText.next(text);
    this._currentPage.next(1);
    this.filterList();
  }

  protected readonly _ragBreakdown = new BehaviorSubject<RAGBreakdown[]>([]);
  readonly ragBreakdown$ = this._ragBreakdown.asObservable();
  get ragBreakdown(): RAGBreakdown[] {
    return this._ragBreakdown.getValue();
  }

  protected readonly _rags = new BehaviorSubject<RAGStatus[]>([]);
  readonly rags$ = this._rags.asObservable();
  get rags(): RAGStatus[] {
    return this._rags.getValue();
  }
  set rags(rags: RAGStatus[]) {
    this._rags.next(rags);
    this._currentPage.next(1);
    this.filterList();
  }

  protected readonly _itemsPerPage = new BehaviorSubject<number>(20);
  readonly itemsPerPage$ = this._itemsPerPage.asObservable();
  get itemsPerPage(): number {
    return this._itemsPerPage.getValue();
  }
  set itemsPerPage(itemsPerPage: number) {
    this._itemsPerPage.next(itemsPerPage);
  }

  protected readonly _currentPage = new BehaviorSubject<number>(1);
  readonly currentPage$ = this._currentPage.asObservable();
  get currentPage(): number {
    return this._currentPage.getValue();
  }
  set currentPage(currentPage: number) {
    this._currentPage.next(currentPage);
  }

  protected readonly _scrollToIndex = new BehaviorSubject<number>(null);
  readonly scrollToIndex$ = this._scrollToIndex.asObservable();
  get scrollToIndex(): number {
    return this._scrollToIndex.getValue();
  }
  set scrollToIndex(scrollToIndex: number) {
    this._scrollToIndex.next(scrollToIndex);
  }

  protected readonly _loading = new BehaviorSubject<boolean>(true);
  readonly loading$ = this._loading.asObservable();
  get loading(): boolean {
    return this._loading.getValue();
  }

  private refreshListQueue = new Subject<void>();
  private listRefreshed = new Subject<void>();

  protected readonly stringSearchProperties = ['title', 'id'];
  protected readonly subscriptions = new Subscription();

  private isRefreshQueueInitiated = false;
  protected readonly _isListInitiated = new BehaviorSubject<boolean>(false);
  readonly isListInitiated$ = this._isListInitiated.asObservable();
  get isListInitiated(): boolean {
    return this._isListInitiated.getValue();
  }

  protected objectType: ObjectTypes;
  protected filters: FilterViewModel[] = [];
  get currentFilters(): FilterViewModel[] {
    return this.filters;
  }
  protected headerFilters: FilterViewModel[] = [];
  protected fixedHeaderFilters: FilterViewModel[] = [];
  protected inputFilters: FilterViewModel[] = [];
  get currentInputFilters(): FilterViewModel[] {
    return this.inputFilters;
  }
  protected readonly apiService: IApiService;

  constructor(
    protected readonly headerFiltersService: HeaderFiltersService,
    protected readonly fixedHeaderFiltersService: FixedHeaderFiltersService,
    protected readonly sortingService: SortingService,
    protected readonly listOperationsService: ListOperationsService<T>,
    protected readonly listFilteringService: ListFilteringService<T>,
    protected readonly commentsEventEmitter: CommentEventsEmitter,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly moduleService: ModuleService,
    protected readonly trackingService: TrackingService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initList(inputFilters: FilterViewModel[] = [], forceInitialisation?): Observable<undefined> {
    this.headerFilters = this.headerFiltersService.currentFilters;
    this.fixedHeaderFilters = this.fixedHeaderFiltersService.currentFilters;
    this.refreshFilters();

    if (this.isListInitiated && !forceInitialisation) {
      this._loading.next(false);
      if(this.trackingView){
        this.trackingService.trackInitializedView(this.trackingView)
      }
      return of(undefined);
    }

    // NOTE: Add a debounce to prevent unnecessary requests from firing.
    // This happens when header and fixed header filter updates are triggerred at the same time.
    this.refreshListQueue.pipe(debounceTime(0)).subscribe(() => {
      this._loading.next(true);

      this.getList().subscribe((items) => {
        this.updateList(items);
        this._loading.next(false);
        if(this.trackingView){
          this.trackingService.trackInitializedView(this.trackingView)
        }
        this.listRefreshed.next();
      });
    });

    this.isRefreshQueueInitiated = true;

    if (this.objectEventEmitters.listFilters) {
      inputFilters.push(...this.objectEventEmitters.listFilters);
    }

    return this.refreshList(inputFilters).pipe(
      switchMap(() => {
        this.initSubscriptions();
        this._isListInitiated.next(true);
        return of(undefined);
      })
    );
  }

  refreshList(inputFilters?: FilterViewModel[]): Observable<undefined> {
    if (!this.isRefreshQueueInitiated) {
      throw 'Cannot refresh list state because it has not been initialized.';
    }

    this.inputFilters = inputFilters ? (JSON.parse(JSON.stringify(inputFilters)) as FilterViewModel[]) : this.inputFilters;
    this.refreshFilters();

    this.refreshListQueue.next();

    return this.listRefreshed.pipe(
      take(1),
      switchMap(() => of(undefined))
    );
  }

  protected updateList(items: T[]): void {
    const sortingSettings = this.sortingService.getEmployeeCardFilterSetting(this.objectType);

    if (items.length && sortingSettings?.showingFilters) {
      items = this.sortingService.sortListByFilters<T>(
        items,
        sortingSettings.primarySortFilter,
        sortingSettings.primarySortAscending,
        sortingSettings.primarySortFilterDateProperty
      );
    }

    this._items.next(items);
    this.filterList();
  }

  protected getList(): Observable<T[]> {
    return this.apiService.list(this.filters) as Observable<T[]>;
  }

  protected abstract updateRAGBreakdown(items: T[]): void;

  protected getPreFilteredList(): T[] {
    return this.items;
  }

  protected filterList(): void {
    let newFilteredItems = this.getPreFilteredList();

    newFilteredItems = this.listFilteringService.performListSearchFilter(
      newFilteredItems,
      this.stringSearchProperties,
      this.searchText
    );

    // TODO: Excluded IDs
    // if (this.excludeIDs?.length){
    //     newFilteredItems = newFilteredItems.filter(item => !this.excludeIDs.includes(+item.id));
    // };

    this.updateRAGBreakdown(newFilteredItems);
    newFilteredItems = this.listFilteringService.performListRAGFilter(newFilteredItems, this.rags, this.objectType);

    this._filteredItems.next(newFilteredItems);
  }

  addItem(item: T, idProp = 'id', addToStart = false): void {
    this.updateList(this.listOperationsService.addObjectToList(item, this.items, idProp, addToStart));
  }

  deleteItem(item: T, idProp = 'id'): void {
    this.updateList(this.listOperationsService.deleteObjectFromList(item, this.items, idProp));
  }

  updateItem(item: T, idProp = 'id'): void {
    this.updateList(this.listOperationsService.updateObjectInList(item, this.items, idProp));
  }

  updateItemLight(filterChanges: LightFilterUpdateModel): void {
    this.updateList(this.listOperationsService.updateObjectInListLight(this.items, filterChanges));
  }

  getItem(idValue: unknown, idProp = 'id'): T | undefined {
    return this.listOperationsService.getObjectFromList(idValue, this.items, idProp);
  }

  protected refreshFilters(): void {
    this.filters = FilterUtilities.MergeFilterArrays(this.inputFilters, this.fixedHeaderFilters, this.headerFilters)
      .filter(f => f.filterType !== FilterTypes.Incident_Status);

    this.filters = this.filters.map((f) => {
      if (f.filterType === FilterTypes.Keyword) {
        f.displayForGlobalObjectType = this.objectType;
      }

      return f;
    });
  }

  protected initSubscriptions(): void {
    if (this.isListInitiated) {
      return;
    }

    this.initHeaderFiltersChangedSubscription();
    this.initSortingSubscription();
    this.initObjectAddedSubscription();
    this.initObjectDeletedSubscription();
    this.initObjectUpdatedSubscription();
    this.initObjectArchivedSubscription();
    this.initObjectUpdatedLightSubscription();
    this.initListFiltersSubscription();
  }

  protected initHeaderFiltersChangedSubscription(): void {
    this.subscriptions.add(
      this.headerFiltersService.filtersChanged$.subscribe((newFilters) => {
        const hasCommonObjTypes = this.moduleService.currentObjectTypes.includes(this.objectType);
        const hasRelatedFilters = FilterUtilities.ArrayContainsFiltersForObjectTypes(newFilters, [this.objectType]);

        if (hasCommonObjTypes || hasRelatedFilters) {
          this.headerFilters = newFilters;
          this.refreshList().subscribe();
        }
      })
    );

    this.subscriptions.add(
      this.fixedHeaderFiltersService.filtersChanged$.subscribe((newFilters) => {
        const hasCommonObjTypes = this.moduleService.currentObjectTypes.includes(this.objectType);

        if (hasCommonObjTypes) {
          this.fixedHeaderFilters = newFilters;
          this.refreshList().subscribe();
        }
      })
    );
  }

  protected initSortingSubscription(): void {
    this.subscriptions.add(
      this.sortingService.employeeSetting$.subscribe(() => {
        this.updateList(this.items);
      })
    );
  }

  protected initObjectAddedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectAdded$.subscribe((res) => {
        if (res.globalObjectType === this.objectType) {
          const item = res.model as T;
          const existingItem = this.getItem(item.id);

          if(this.doesNewObjectFitsFilters(item)){
            this.addItem(item);
          } else if (this.shouldShowArchivedItems()){
            // When user unarchives task it comes trough objectAdded event, but it doesn't fit Archive/Unarchive filter, the task should be removed
            this.deleteItem(existingItem);
          }
        }
      })
    );
  }

  protected initObjectDeletedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectDeleted$.subscribe((res) => {
        if (res.globalObjectType === this.objectType) {
          this.deleteItem(res.model as T);
        }
      })
    );
  }

  protected initObjectArchivedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectArchived$.subscribe((res) => {
        if (res.globalObjectType === this.objectType) {
          // double check if object has filters, because there is a bug for some objects that don't have filters in the broadcasted model
          const hasFilters = res.model.filters?.length > 0;
          const doesItemFitsFilters = this.doesNewObjectFitsFilters(res.model);

          doesItemFitsFilters && hasFilters ? this.addItem(res.model) : this.deleteItem(res.model);
        }
      })
    );
  }
  protected initObjectUpdatedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectUpdated$.subscribe((res) => {
        if (res.globalObjectType !== this.objectType) {
          return;
        }

        const updatedObject = res.model as T;
        const item = this.getItem(updatedObject.id);
        if (item) {
          const shouldDelete = this.shouldDeleteItem(res);
          if (shouldDelete) {
            this.deleteItem(res.model as T);
          } else {
            this.updateItem(res.model as T);
          }
        }
      })
    );
  }

  protected initObjectUpdatedLightSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.listLightUpdated$.subscribe((res) => {
        if (res.globalObjectType === this.objectType) {
          this.updateItemLight(res.model as LightFilterUpdateModel);
        }
      })
    );
  }

  /**
   * Check if item should be deleted from the list after it being updated
   * @param {ObjectEventModel} res  signalR model
   */
  protected shouldDeleteItem(res: ObjectEventModel): boolean {
    const objectFilters = res.model.filters as FilterViewModel[];
    const filtersToGroup = this.filters.filter(
        (f) => f.filterValue && f.filterValue != '0' && f.filterType !== FilterTypes.Date && !f.exclude
      );

    const groupedByFilterType = FilterUtilities.groupFiltersByFilterType(filtersToGroup);

    // For each filter type, check the object filters contains at least 1 of the main filters
    for (const [filterType, filterViewModels] of groupedByFilterType.entries()) {
      const objectFiltersByFilterType = objectFilters.filter(f => f.filterType === filterType);
      const filtersContainsObjectFilters = filterViewModels.some(f => objectFiltersByFilterType.some(of => of.filterValue.toString() === f.filterValue.toString()));
      if(!filtersContainsObjectFilters)
        return true;
    }
    return false;
  }

  private initListFiltersSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.listFilterAdded$.subscribe((filters) => {
        this.refreshList(filters).subscribe();
      })
    );

    this.subscriptions.add(
      this.objectEventEmitters.listFilterCleared$.subscribe((filters) => {
        if (this.inputFilters) {
          this.inputFilters = this.inputFilters.filter(
            (inputFilter) =>
              filters.find((f) => f.filterType === inputFilter.filterType && f.filterValue === inputFilter.filterValue) ===
              undefined
          );
        }

        this.refreshList().subscribe();
      })
    );
  }

  /**
   * Check if new object fits current applied filters
   */
  protected doesNewObjectFitsFilters(newObject: {filters: FilterViewModel[]}): boolean {
    const filters = JSON.parse(JSON.stringify(this.currentFilters)) as FilterViewModel[];
    // If there is no ARCHIVED filter, then we create one with value false, because by default we want to show only active items
    if (!filters.find((f) => f.filterType === FilterTypes.Archived)) {
      filters.push(FilterUtilities.GenerateFilter(FilterTypes.Archived, false))
    }

    const comparisonCallbacks = [];
    filters.forEach((filter) => {
      comparisonCallbacks.push(FilterUtilities.getAppliedFilterCallback(filter));
    })

    return comparisonCallbacks.every((cb) => cb(newObject));
  }

  private shouldShowArchivedItems(): boolean {
    const archivedFilter =this.currentFilters.find((f) => f.filterType === FilterTypes.Archived);
    return archivedFilter && FilterUtilities.getFilterValueAsBoolean(archivedFilter);
  }
}
