<ion-app class="app-ion-wrapper">
  <ng-container *ngIf="hasInternetConnection">
    <ng-container #wrapper>
      <ng-container *ngIf="!loading">
        <div class="global-wrapper ion-app">
          <ion-split-pane
            contentId="content"
            when="md"
            [class.expanded]="isSidebarToggled"
            [class.ganttFullScreen]="isGanttFullscreen"
          >
            <ion-menu [class.hidden]="maxPageRequestedByComponent || !isNotUnauthorizedPage" contentId="content" #ionMenu>
              <app-side-navbar
                style="height: 100%"
                #sidebar
                class="side-bar"
                [currentAccount]="currentAccount"
                [currentEmployee]="currentEmployee"
                *ngIf="currentEmployee && isNotUnauthorizedPage"
              ></app-side-navbar>
            </ion-menu>
            <div id="content" class="right-container">
              <app-global-alerts-bar></app-global-alerts-bar>
              <app-header-bar
                [class.hidden]="maxPageRequestedByComponent || maxHeightRequestedByComponent"
                #navbar
                [currentAccount]="currentAccount"
                [currentEmployee]="currentEmployee"
                *ngIf="currentEmployee && isNotUnauthorizedPage"
              ></app-header-bar>
              <div class="app-body">
                <main
                  #main
                  class="main"
                  [class.sidebar-visible]="currentEmployee"
                  [class.header-visible]="currentEmployee"
                  [class.sidebar-expanded]="!sidebarIsCollapsed"
                >
                  <div cdkScrollable class="container-fluid custom-scrollbar" #routeContainer>
                    <router-outlet #myOutlet="outlet"></router-outlet>
                  </div>
                </main>
              </div>
            </div>
          </ion-split-pane>
          <div class="sidebar-toggle-container"
            *ngIf="!isMobile && !isGanttFullscreen && !maxPageRequestedByComponent && isNotUnauthorizedPage"
            [class.expanded]="isSidebarToggled"
            (click)="toggleSidebar()"
            >
            <i class="material-symbols-outlined menu-icon" [class.opened]="isSidebarToggled">menu_open</i>
          </div>
          <app-side-filters></app-side-filters>
          <app-confirm-modal></app-confirm-modal>
          <app-tooltip></app-tooltip>
          <app-popup></app-popup>
          <app-dropdown-content></app-dropdown-content>
          <ng-progress
            #progressBar
            [speed]="200"
            [spinner]="false"
            (completed)="this.loading = false"
          >
          </ng-progress>
        </div>
      </ng-container>
      <ng-container *ngIf="loading">
        <app-loading [displayLogo]="true">
          <p>Getting ready...</p>
        </app-loading>
      </ng-container>
    </ng-container>
  </ng-container>
</ion-app>
