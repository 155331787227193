<div *ngIf="filterType && showHeader" class="filters-inline-edit-lozenges-header">
  <div *ngIf="!label" class="filters-inline-edit-lozenges-header-title">
    {{ filterType | localiseFilterType }}
  </div>
  <div *ngIf="label" class="filters-inline-edit-lozenges-header-title">
    {{ label }}
  </div>
  <i class="material-symbols-outlined" *ngIf="!disabled"  (click)="onClose()">close</i>
</div>
<ng-container *ngIf="appliedFilters && appliedFilters.length > 0; else noItemsTemplate">
  <div class="filters-inline-edit-lozenges-body">
    <ng-container *ngFor="let filter of appliedFilters">
      <app-chip *ngIf="filter.filterText"
        [disabled]="disabled"
        [tooltip]="{message: filter.filterText}"
        [class.primary-chip]="isPrimaryFunctionalityAllowed"
        [isActionsGroup]="isActionsGroup"
        (lozengeClicked)="!isActionsGroup ? onFilterRemove(filter) : null"
      >
        <app-employee-avatar *ngIf="filterType === filterTypes.Owner"
          class="mr-8"
          [size]="2"
          [employee]="getEmployee(filter.filterValue)">
        </app-employee-avatar>

          {{ filter.filterText }}

        <div class="primary-wrapper" *ngIf="isPrimaryFunctionalityAllowed">
          <i
            class="material-symbols-outlined star-symbol"
            [class.primary]="filter.isPrimary"
            (mouseover)="isPrimaryIconHovered = true"
            (mouseout)="isPrimaryIconHovered = false"
            (click)="$event.stopPropagation(); onPrimaryClick(filter)">star
          </i>
        </div>
      </app-chip>
    </ng-container>
  </div>
</ng-container>
<ng-template #noItemsTemplate>
  <div class="filters-inline-edit-lozenges-text">{{ T.common.no_items_selected | translate }}</div>
</ng-template>
